export type FailedResponse = {
    status: boolean;
    data: {
        data: {
            message: string;
        };
    };
};

export type SuccessResponse = {
    status: boolean;
    data: {
        data: [];
    };
};

export type CreateSuccessResponse = {
    status: boolean;
    data: {
        data: {
            id: number;
        };
    };
};

export type DeleteSuccessResponse = Pick<CreateSuccessResponse, 'status' | 'data'>;

/**
 * FailedResponse用TypeGuard
 * @param arg
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isFailedResponse = (arg: any): arg is FailedResponse => {
    return arg.data.data.message !== undefined;
};
